<template lang="pug">
  div.wrap-module-slot-selector
    NowLoading(:show="showNowLoading")
    div(v-if="meeting").slot-selector-content
      //- div.sub-header.f.fc
        div.sub-header-content.f.fm.flex-between
          v-icon(@click="$refs.calendar.prev()"
            v-if="showPrevIcon" size="32px") mdi-chevron-left
          span.px4 {{this.dayLabel}}
          v-icon(@click="$refs.calendar.next()"
            v-if="showNextIcon" size="32px") mdi-chevron-right
      div.wrap-calendar.pb60
        v-calendar(ref="calendar"
          v-model="value"
          type="4day"
          event-overlap-mode="stack"
          locale="ja"
          category-hide-dynamic
          :event-color="getEventColor"
          :events="slots"
          :intervalMinutes="intervalMinutes"
          :interval-height="intervalHeight"
          :interval-count="intervalCount"
          :interval-format="intervalFormatter"  
          @click:event="clickEvent"
          @click:time="clickTime")
          //- :first-time="firstTime"
          template(v-slot:event="props")
            div(v-if="props.event.type == 'gc_slot'").gc-event
              span.fz12.pt2.block.px2 {{ props.event.name }}
            div(v-if="props.event.type == 'available_slot'"
              :class="{'deactive': props.event.isInitialSlot && !props.event.active}").available-slot.f.fc
              span.fz10.pt2 {{ props.eventParsed.start.time }}
          template(#day-body="{ date, week }")
            div(:style="{ top: nowY }"
              :class="{ first: date === week[0].date }").v-current-time.block
    div.wrap-tools
      div.tools.pt8
        div.f.fm.flex-between
          div(@click="onAllSelect").all-select-toggle.f.fm.cursor-pointer
            v-icon(size="22" color="#4285f4").mr4 {{isSelectingAll ? 'mdi-calendar-blank' : 'mdi-calendar-month-outline'}}
            span {{isSelectingAll ? '全て選択解除' : '全て選択'}}
          div.f.fm.flex-between
            v-icon(@click="$refs.calendar.prev()"
              v-if="showPrevIcon" size="32px") mdi-chevron-left
            span.px4 {{this.dayLabel}}
            v-icon(@click="$refs.calendar.next()"
              v-if="showNextIcon" size="32px") mdi-chevron-right
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
$sub_header_height: 36px;
$send_btn_height: 72px;
.wrap-module-slot-selector {
  width: 100%;
  padding-top: $header_height;
  position: relative;
  .slot-selector-content {
    width: 100%;
    margin: 0 auto;
    position: relative;
    // .sub-header {
    //   position: fixed;
    //   width: 100%;
    //   height: $sub_header_height;
    //   top: 48px;
    //   z-index: 100;
    //   background: #fff;
    //   border-bottom: solid $border_size $border_color;
    // }
    // .wrap-calendar {
    //   position: relative;
    //   top: $sub_header_height;
    // }
  }
  .wrap-tools {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: $send_btn_height;
    background: $primary_bg_color;
    z-index: 1;
    border-top: $basic_border;
    .tools {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      margin: 0 auto;
      .all-select-toggle {
        color: $active_color;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/scss/_variables.scss';

.wrap-module-slot-selector {
  .v-btn__content {
    color: #000;
  }
  .v-calendar-daily__head {
    position: fixed;
    width: 100%;
    // max-width: $base_max_width_px;
    overflow-y: scroll;
    z-index: 100;
    background: #fff;
  }
  .v-calendar-daily__body {
    padding-top: 80px;
  }
  .v-calendar-daily__intervals-head,
  .v-calendar-daily__intervals-body {
    width: 46px !important;
  }
  .v-event-timed-container {
    margin-right: 0;
  }
  // blue-grey is gc event slot
  .v-event-timed {
    &.darken-1 {
      max-width: 70%;
    }
  }

  .v-current-time {
    height: 2px;
    background-color: $secondary_text_color;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;
    &.first::before {
      content: '';
      position: absolute;
      background-color: $secondary_text_color;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-top: -5px;
      margin-left: -6.5px;
    }
  }

  .available-slot {
    width: 100%;
    height: 100%;
    background: $active_color;
    &.deactive {
      background: none;
      border: dashed 1.8px $active_color;
      span {
        color: $active_color;
      }
    }
  }
}
</style>

<script>
import { format, startOfDay, addDays, addMinutes, isAfter, isBefore } from 'date-fns'
import { api } from '@/components/utils/config'
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Mixin from '@/components/mixin'
import NowLoading from '@/components/shared/NowLoading'

export default {
  mixins: [Mixin],
  components: {
    NowLoading
  },
  computed: {
    ...mapStateAuth(['uid']),
    dayLabel() {
      return format(this.value, 'yyyy/MM/dd')
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    }
  },
  props: {
    gcEvents: {
      type: Array,
      default: null
    }
  },
  watch: {
    slots() {
      const activeAvailableSlots = this.slots
        .filter((slot) => slot.type === 'available_slot')
        .filter((slot) => !slot.isInitialSlot || (slot.isInitialSlot && slot.active))
      this.$emit('setAvailableSlotsNum', activeAvailableSlots.length)
    }
  },
  data() {
    return {
      cal: null,
      value: new Date(),
      meeting: null,
      candidateSlots: null,
      slots: [],
      firstAllSlots: [],
      intervalHeight: null,
      intervalMinutes: null,
      intervalCount: null,
      firstTime: null,
      eventIsClicked: false,
      showPrevIcon: true,
      showNextIcon: true,
      showNowLoading: false,
      isSelectingAll: true
    }
  },
  async created() {
    this.meeting = await database.meetingCollection().findById(this.$route.params.meetingId)

    // this.meeting.createdAt = this.meeting.createdAt.toDate()
    // this.meeting.startedAt = this.meeting.startedAt.toDate()
    // this.meeting.dateRange = {
    //   start: this.meeting.dateRange.start.toDate(),
    //   end: this.meeting.dateRange.end.toDate()
    // }
    // console.log(this.meeting)

    // intervalHeight * slotNumInHour = hourIntervalHeight
    const hourIntervalHeight = 64
    const slotNumInHour = 60 / this.meeting.duration
    this.intervalHeight = hourIntervalHeight / slotNumInHour
    this.intervalMinutes = this.meeting.duration
    this.intervalCount = Math.floor((24 * 60) / this.intervalMinutes)
    this.firstTime = this.meeting.timeRange.start

    this.candidateSlots = []
    if (this.meeting.candidateSlots) {
      this.candidateSlots = this.meeting.candidateSlots.map((slot) => {
        return {
          start: slot.start.toDate(),
          end: slot.end.toDate()
        }
      })
    } else {
      // According to line 272, this function was implemented on server, and then line 265 was commented out
      // It should have been running locally from the start, it would have much better performance
      this.candidateSlots =
        this.generateSlotsByCondition({
          ...this.meeting,
          dateRange: {
            start: this.meeting.dateRange.start.toDate(),
            end: this.meeting.dateRange.end.toDate()
          }
        }) || []
    }

    // if (this.candidateSlots) {
    //   this.candidateSlots = this.candidateSlots.map((slot) => {
    //     slot.start = slot.start.toDate()
    //     slot.end = slot.end.toDate()
    //     return slot
    //   })
    //   // eventがある場合は被るスロットをあらかじめフィルターする
    //   if (this.gcEvents) {
    //     this.showNowLoading = true

    //     let param = { meetingId: this.$route.params.meetingId }
    //     if (this.gcEvents) param.events = this.gcEvents.filter((e) => !e.isAllDayEvent)
    //     const response = await fetch(`${api}/createInitialCandidateSlots`, {
    //       method: 'POST',
    //       mode: 'cors',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json'
    //       },
    //       // body: JSON.stringify(this.meeting)
    //       body: JSON.stringify(param)
    //     })
    //     const result = await response.json()
    //     this.candidateSlots = result

    //     this.showNowLoading = false
    //   }
    // } else if (!this.candidateSlots || this.meeting.rescheduledBy) {
    //   this.showNowLoading = true

    //   let param = { meetingId: this.$route.params.meetingId }
    //   if (this.gcEvents) param.events = this.gcEvents.filter((e) => !e.isAllDayEvent)
    //   const response = await fetch(`${api}/createInitialCandidateSlots`, {
    //     method: 'POST',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Accept: 'application/json'
    //     },
    //     // body: JSON.stringify(this.meeting)
    //     body: JSON.stringify(param)
    //   })
    //   const result = await response.json()
    //   this.candidateSlots = result

    //   this.showNowLoading = false
    // }

    // // console.log('candidateSlots:', candidateSlots)
    // this.slots = this.candidateSlots.map(e => {
    //   return {
    //     name: '',
    //     start: new Date(e.start),
    //     end: new Date(e.end),
    //     timed: true,
    //     type: 'available_slot',
    //     isInitialSlot: true,
    //     active: true
    //   }
    // }).filter(e => {
    //   const now = new Date()
    //   return isAfter(e.start, now)
    // })
    // console.log('this.slots', this.slots)

    // if (this.gcEvents) {
    //   const gcSlots = this.gcEvents.map((e) => {
    //     let event = { name: e.name, type: 'gc_slot' }
    //     if (e.isAllDayEvent) {
    //       event.start = new Date(e.start)
    //       event.timed = false
    //     } else {
    //       event.start = new Date(e.start)
    //       event.end = new Date(e.end)
    //       event.timed = true
    //     }
    //     return event
    //   })
    //   this.slots = this.slots.concat(gcSlots)
    // }

    // new code--------
    let events = []
    let gcSlots
    if (this.gcEvents) {
      gcSlots = this.gcEvents.map((e) => {
        let event = { name: e.name, type: 'gc_slot' }
        if (e.isAllDayEvent) {
          event.start = new Date(e.start)
          event.timed = false
        } else {
          event.start = new Date(e.start)
          event.end = new Date(e.end)
          event.timed = true
        }
        return event
      })
      events = events.concat(this.gcEvents)
    }
    const candidateSlots = this.filterCandidateSlotsByEvents({
      candidateSlots: this.candidateSlots,
      events
    })
    this.slots = candidateSlots.map((slot) => {
      return {
        name: '',
        timed: true,
        type: 'available_slot',
        isInitialSlot: true,
        active: true,
        start: slot.start,
        end: slot.end
      }
    })
    if (this.gcEvents) {
      const gcSlots = this.gcEvents.map((e) => {
        let event = { name: e.name, type: 'gc_slot' }
        if (e.isAllDayEvent) {
          event.start = new Date(e.start)
          event.timed = false
        } else {
          event.start = new Date(e.start)
          event.end = new Date(e.end)
          event.timed = true
        }
        return event
      })
      this.slots = this.slots.concat(gcSlots)
    }

    // The next line cause a gcSlots duplication, the slots is already concatted at line 387
    // if (this.gcEvents) this.slots = this.slots.concat(gcSlots)
    // ----------------
    this.firstAllSlots = Array.from(this.slots) // 配列をコピー

    this.value = this.meeting.dateRange.start.toDate()

    setTimeout(this.scrollToRangeStart, 800)
  },
  async mounted() {
    setTimeout(() => {
      this.cal = this.$refs.calendar
    }, 2000)
  },
  methods: {
    scrollToRangeStart() {
      // 対象の範囲の時間まで強制スクロール
      const intervals = document.getElementsByClassName('v-calendar-daily__interval')
      let targetElem
      for (let i = 0; i < intervals.length; i++) {
        if (intervals[i].innerText.split(':')[0] === this.meeting.timeRange.start.split(':')[0]) {
          targetElem = intervals[i]
          break
        }
      }
      const calendarHeader = document.getElementsByClassName('v-calendar-daily__head')[0]
      window.scrollTo(0, targetElem.offsetTop - calendarHeader.offsetHeight)
    },
    clickEvent(e) {
      console.log('clickEvent', e)
      if (e.event.type !== 'available_slot') return

      const clickedSlot = this.slots.filter((slot) => {
        return e.event.start == slot.start && e.event.end == slot.end
      })[0]

      if (clickedSlot.isInitialSlot) {
        // 自動生成されたスロットだった場合は点線にする
        this.slots = this.slots.map((slot) => {
          if (e.event.start == slot.start && e.event.end == slot.end) {
            slot.active = !slot.active
          }
          return slot
        })
      } else {
        // クリックされたスロットを削除
        this.slots = this.slots.filter((slot) => {
          return !(e.event.start == slot.start && e.event.end == slot.end)
        })
      }

      this.eventIsClicked = true
      setTimeout(() => {
        this.eventIsClicked = false
      }, 100)
    },
    clickTime(e) {
      console.log('clickTime:', e)
      const clickedDate = new Date(`${e.year}/${e.month}/${e.day} ${e.hour}:${e.minute}`)
      if (this.eventIsClicked) return

      // スロットがクリックされた時はスキップする
      const existingSlot = this.slots
        .filter((slot) => {
          return isBefore(clickedDate, slot.end) && isAfter(clickedDate, slot.start)
        })
        .filter((slot) => {
          return slot.type === 'available_slot'
        })[0] // availableSlotのみ選択

      if (existingSlot) return
      // スロットの正規化
      // クリック時にその日をスロットに分割して該当のスロットを抽出する
      const slotsInDay = this.getSlotsInDay(clickedDate)
      const clickedSlot = slotsInDay.filter((slot) => {
        return isAfter(clickedDate, slot.start) && isBefore(clickedDate, slot.end)
      })[0]
      if (!clickedSlot) return
      const slot = {
        name: '',
        start: clickedSlot.start,
        end: clickedSlot.end,
        timed: true,
        active: true,
        type: 'available_slot'
      }
      this.slots.push(slot)
    },
    getSlotsInDay(date) {
      let currentDate = startOfDay(date)
      // let rangeStart = startOfDay(date)
      let rangeEnd = startOfDay(addDays(date, 1))
      let result = []

      // while (true) {
      // for (let i = 0; i < 100000; i++) {
      for (;;) {
        // to avoid warning
        const slot = {
          start: currentDate,
          end: addMinutes(currentDate, this.meeting.duration)
        }
        result.push(slot)
        currentDate = addMinutes(currentDate, this.meeting.duration)
        if (isAfter(currentDate, rangeEnd)) break
      }

      return result
    },
    intervalFormatter(event) {
      return event.time
    },
    getEventColor(event) {
      // return event.type === 'available_slot' ? 'blue lighten-2' : '#999'
      if (event.type === 'available_slot') {
        return '#fff'
      } else if (event.type === 'gc_slot') {
        return '#999'
      }
    },
    checkValue(props) {
      console.log('checkValue:', props)
    },
    onAllSelect() {
      if (this.isSelectingAll) {
        // 全解除
        this.slots = this.slots
          .map((slot) => {
            if (slot.type === 'gc_slot') return slot
            if (slot.type === 'available_slot') {
              slot.active = false
              return slot
            }
          })
          .filter(
            (slot) =>
              (slot.type === 'available_slot' && slot.isInitialSlot) || slot.type === 'gc_slot'
          )
      } else {
        // 全選択
        console.log('this.firstAllSlots:', this.firstAllSlots)
        this.slots = this.firstAllSlots.map((slot) => {
          if (slot.type === 'gc_slot') return slot
          if (slot.type === 'available_slot') {
            slot.active = true
            return slot
          }
        })
      }
      this.isSelectingAll = !this.isSelectingAll
    }
  }
}
</script>
